<template>
  <div class="flex-align" @click="clickDetail(card)">
    <div class="card-img" :style="imgField(card.imgs[0], 'mobile')"></div>
    <div class="card-content">
      <!-- 파트너 -->
      <div class="flex-align" v-if="card.partner">
        <!--<img style="width:24px;height:24px"
             class="margin-right-8"
             :src="card.partner.img" v-if="card.partner.img">-->
        <div class="body5 sub3">{{ card.partner.name }}</div>
      </div>
      <div class="subtitle6 main margin-top-4">{{ card.name }}</div>
      <div class="body5 sub2 ellipsis-1">{{ card.simple_desc }}</div>
      <!-- 가격 정보 -->
      <div v-if="discountedPrice(card)>0">
        <div class="body5 sub4 text-line-through" v-if="isDiscount(card)">{{ card.price.price | currency }}</div>
        <div class="flex-align">
          <div class="margin-right-4 primary body2-bold" v-if="card.price.discount_type===0">{{ discountText(card) }}</div>
          <div class="body2-bold">
            <span class="sub">{{ discountedPrice(card) | currencyNum }}</span>
            <span class="body4 sub2">원</span>
            <span class="body4 sub3" v-if="card.price.is_subscription">/월</span>
            <span class="body4 sub2 margin-left-4" v-if="card.category2!==42">~</span>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  export default {
    name: "CardProductThemeMobile",
    props: {
      card: {
        type: Object
      }
    },
    created() {

    },
    data() {
      return {}
    },
    methods: {
      discountText(product) {
        let text = '';
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            text = product.price.discount_rate + '%'
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            text = product.price.discount_price + '원';
          }
        }
        return text;
      },
      // 할인 여부
      isDiscount(product) {
        if (product.price.is_discount && product.price.discount_start !== null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return !(currentDate < product.price.discount_start || currentDate > product.price.discount_end);
        } else if (product.price.discount_start !== null && product.price.discount_end === null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate >= product.price.discount_start;
        } else if (product.price.discount_start === null && product.price.discount_end !== null) {
          let currentDate = this.dayjs().format('YYYY-MM-DD HH:mm');
          return currentDate <= product.price.discount_end;
        } else {
          return false;
        }
      },
      // 상품 가격
      discountedPrice(product) {
        let total = 0;
        if (this.isDiscount(product)) {
          if (product.price.discount_rate !== 0 && product.price.discount_type === 0) {
            total = product.price.price * (1 - (product.price.discount_rate / 100))
          } else if (product.price.discount_price !== 0 && product.price.discount_type === 1) {
            total = product.price.price - product.price.discount_price;
          }
        } else total = product.price.price;
        return total;
      },
      imgField(img, device) {
        let style = {
          backgroundImage: `url(${this.image(img)})`,
          backgroundSize: 'cover'
        };
        if(device==='pc') {
          style.backgroundPosition = 'center';
        } else {
          style.backgroundPosition = 'top';
        }
        return style;
      },
      clickDetail(card) {
        this.routeGa('',`플랫폼 팩 카드(${card.name})`, card.name);
        let path = 'market';
        if(this.$route.path.indexOf('app_market')>-1) {
          path = 'app_market';
        }

        this.$router.push(`/${path}/detail?id=${card.id}`);
      },
    }
  }
</script>

<style lang="stylus" scoped>
  @import '~assets/css/lp_main'

  .card-img
    position relative
    width 120px
    height 120px
    border-radius 8px
    background-color $gray4
    border 1px solid $gray3

  .card-content
    margin-left 20px
    flex 1

  .card-img:hover
    .card-img-hover
      display grid

  .card-img-hover
    display none
    position absolute
    top 0
    left 0
    background rgba(0,0,0,0.3)
    width 100%
    height 100%
    align-items center
    justify-content center
    grid-template-columns repeat(2, 1fr)
    grid-column-gap 12px
    padding 36px

  .card-content
    padding-top 12px
</style>
